@font-face {
  font-family: Montserrat;
  src:url("assets/fonts/Montserrat/static/Montserrat-Regular.ttf");
}
@font-face {
  font-family: Montserrat-Medium;
  src:url("assets/fonts/Montserrat/static/Montserrat-Medium.ttf");
}
@font-face {
  font-family: Montserrat-Bold;
  src:url("assets/fonts/Montserrat/static/Montserrat-Bold.ttf");
}
@font-face {
  font-family: Montserrat-Semi-Bold;
  src:url("assets/fonts/Montserrat/static/Montserrat-SemiBold.ttf");
}

*{
  margin: 0;
  padding: 0;
  font-family: Montserrat;
  box-sizing: border-box;
}
.container{
  max-width: 1231px;
  width: 100%;
  margin: 0 auto;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.ReactModal__Overlay.ReactModal__Overlay--after-open{
  background-color: #00000012 !important;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.basket{
  width: 300px;
  height: 80px;
  background-color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 1024px) {
  .container {
    max-width: 950px;
  }
}

@media only screen and (max-width: 768px) {
  .container {
    max-width: 650px;
  }
}

