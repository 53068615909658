* {
    background-color: transparent;
}


.flip-card {
    background-color: transparent;
    width: 508px;
    height: 366px;
    border: 1px solid #CDD7E1;
    perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
    -webkit-transform-style:preserve-3d;
}

.flip-btn {
    position: absolute;
    right: 169px;
    bottom: 32px;
    z-index: 1;
    background: transparent;
    color: #D32B2E;
    border: none;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.flip-btn2 {
    position: absolute;
    right: 30px;
    bottom: 32px;
    z-index: 1;
    background: transparent;
    color: #D32B2E;
    border: none;
    display: flex;
    align-items: center;
    cursor: pointer;
}


.refresh_icon {
    margin-right: 7px;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-btn {
    opacity: 1;
    transition: 300ms ease opacity;

}
/*.flip-btn:focus{*/
/*    opacity: 0;*/
/*}*/
.flip-btn2:focus{
    opacity: 0;
}


.flip-btn.focus + .flip-card .flip-card-inner {
    transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    -moz-backface-visibility:hidden;
    backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
    background-color: #bbb;
    color: black;
}

/* Style the back side */
.flip-card-back {
    background-color: #CDD7E1;
    text-align: center;
    margin: auto;
    color: #021E3A;
    transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.card {
    width: 508px;
    height: 366px;
    perspective: 1000px;
    background: #F1F4F6;
    border: 1px solid #CDD7E1;
    box-shadow: 0px 4px 25px rgba(100, 100, 100, 0.25);
    border-radius: 6px;
    position: relative;
}

.card_inner {
    margin: 14px;
    height: 339px;
    border: 1px dashed #005BB9;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.card_inner_back {
    margin: 14px;
    height: 339px;
    border: 1px dashed #005BB9;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 480px;
}

.sound {
    position: absolute;
    bottom: 30px;
    left: 30px;
    cursor: pointer;

}

.react-3d-carousel {
    background-color: white;
}

.word {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #021E3A;
}

.fa-arrow-right:before {
    content: "\f061";
    color: #021E3A;
}

.fa-arrow-left:before {
    content: "\f060";
    color: #021E3A;
}

.react-3d-carousel .slider-container .slider-content .slider-single.active .slider-single-content {
    opacity: 1;
    transform: translateX(0%) scale(1);
    background: #F1F4F6;
}

.react-3d-carousel .slider-container .slider-content .slider-single.proactive .slider-single-content {
    opacity: 0.3;
    transform: translateX(25%) scale(0.8);
    background: #F1F4F6;
}

.react-3d-carousel .slider-container .slider-content .slider-single .slider-single-content {
    background: #F1F4F6;
}

.audio_button {
    border: none;
    cursor: pointer;
}

.react-3d-carousel .slider-container .slider-right div {
    position: absolute !important;
    top: 126% !important;
    left: -290px !important;
    margin-top: -20px !important;
    color: #ffffff !important;
    transform: translateY(-50%) !important;
    padding: 0px 60px 0px 70px !important;
    border-top: none !important;
    border-right: none !important;
    border-bottom: none !important;
    border-left: none !important;
}

.react-3d-carousel .slider-container .slider-left div {
    position: absolute !important;
    top: 127% !important;
    left: 155px !important;
    margin-top: -20px !important;
    color: #ffffff !important;
    transform: translateY(-50%) !important;
    padding: 0px 60px 0px 70px !important;
    border-top: none !important;
    border-right: none !important;
    border-bottom: none !important;
    border-left: none !important;
}

.fa {
    display: inline-block;
    font: normal normal normal 48px/1 FontAwesome !important;
    /*font-size: unset;*/
    /*text-rendering: */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.fa-arrow-right:before {
    color: transparent;
}

.fa-arrow-left:before {
    color: transparent;
}

@media only screen and (max-width: 1270px) {
    .react-3d-carousel .slider-container .slider-right div {
        left: -245px !important;
    }

    .react-3d-carousel .slider-container .slider-left div {
        left: 110px !important;
    }

    .flip-btn {
        right: 129px;
    }
}

@media only screen and (max-width: 1060px) {
    .react-3d-carousel .slider-container .slider-right div {
        left: -235px !important;
    }

    .react-3d-carousel .slider-container .slider-left div {
        left: 100px !important;
    }
}

@media only screen and (max-width: 1024px) {
    .react-3d-carousel .slider-container .slider-right div {
        left: -225px !important;
    }

    .react-3d-carousel .slider-container .slider-left div {
        left: 90px !important;
    }
    .flip-btn {
        right: 109px;
    }
}

@media only screen and (max-width: 960px) {
    .react-3d-carousel .slider-container .slider-right div {
        left: -205px !important;
    }

    .react-3d-carousel .slider-container .slider-left div {
        left: 70px !important;
    }

    .flip-btn {
        right: 79px;
    }
}
@media only screen and (max-width: 920px) {
    .flip-card {
        width: 420px;
    }

    .card {
        width: 420px;
    }
    .card_inner_back {
        width: 390px;
    }
    .flip-btn {
        right: 119px;
    }
}

@media only screen and (max-width: 912px) {
    .react-3d-carousel .slider-container .slider-right div {
        left: -185px !important;
    }

    .react-3d-carousel .slider-container .slider-left div {
        left: 50px !important;
    }

    .react-3d-carousel .slider-container .slider-content .slider-single.active .slider-single-content {
        opacity: 1;
        transform: translateX(0%) scale(1);
        background: #F1F4F6;
    }

}


@media only screen and (max-width: 860px) {
    .react-3d-carousel .slider-container .slider-right div {
        left: -175px !important;
    }

    .react-3d-carousel .slider-container .slider-left div {
        left: 30px !important;
    }

}

@media only screen and (max-width: 820px) {
    .react-3d-carousel .slider-container .slider-right div {
        left: -130px !important;
    }
    .react-3d-carousel .slider-container .slider-right div {
        padding: 0px 50px 0px 55px !important;
    }
    .react-3d-carousel .slider-container .slider-left div {
        left: 20px !important;
    }
    .react-3d-carousel .slider-container .slider-left div {
        padding: 0px 43px 0px 45px !important;
    }

    .flip-btn {
        right: 79px;
    }
}

@media only screen and (max-width: 768px) {
    .react-3d-carousel .slider-container .slider-right div {
        left: -110px !important;
    }

    .react-3d-carousel .slider-container .slider-left div {
        left: 10px !important;
    }
    .flip-card {
        width: 320px;
    }

    .card {
        width: 320px;
    }
    .card_inner_back {
        width: 290px;
    }
    .flip-btn {
        right: 109px;
    }
}

@media only screen and (max-width: 650px) {
    .flip-btn {
        right: 79px;
    }
    .react-3d-carousel .slider-container .slider-right div {
        left: -60px !important;
    }
    .react-3d-carousel .slider-container .slider-right div {
        padding: 0px 50px 0px 30px !important;
    }
    .react-3d-carousel .slider-container .slider-left div {
        left: -25px !important;
    }
    .react-3d-carousel .slider-container .slider-left div {
        padding: 0px 43px 0px 45px !important;
    }
}

@media only screen and (max-width: 580px) {
    .flip-btn {
        right: 59px;
    }
    .flip-card {
        width: 300px;
    }

    .card {
        width: 300px;
    }
    .card_inner_back {
        width: 270px;
    }
    .react-3d-carousel .slider-container .slider-right div {
        left: -160px !important;
    }
    .react-3d-carousel .slider-container .slider-right div {
        padding: 0px 50px 0px 30px !important;
    }
    .react-3d-carousel .slider-container .slider-left div {
        left: 70px !important;
    }
    .react-3d-carousel .slider-container .slider-left div {
        padding: 0px 43px 0px 45px !important;
    }
}

@media only screen and (max-width: 440px) {
    .flip-btn {
        right: 29px;
    }
    .react-3d-carousel .slider-container .slider-right div {
        left: -120px !important;
    }
    .react-3d-carousel .slider-container .slider-right div {
        padding: 0px 50px 0px 40px !important;
    }
    .react-3d-carousel .slider-container .slider-left div {
        left: 30px !important;
    }
    .react-3d-carousel .slider-container .slider-left div {
        padding: 0px 43px 0px 45px !important;
    }
}
@media only screen and (max-width: 414px) {
    .flip-btn {
        right: 19px;
    }
    .react-3d-carousel .slider-container .slider-right div {
        left: -110px !important;
    }
    .react-3d-carousel .slider-container .slider-right div {
        padding: 0px 50px 0px 40px !important;
    }
    .react-3d-carousel .slider-container .slider-left div {
        left: 15px !important;
    }
    .react-3d-carousel .slider-container .slider-left div {
        padding: 0px 43px 0px 45px !important;
    }

}
@media only screen and (max-width: 390px){
    .flip-btn {
        right: 7px;
    }
}
@media only screen and (max-width: 375px) {
    .flip-btn {
        right: 0px;
    }
    .react-3d-carousel .slider-container .slider-right div {
        left: -100px !important;
    }
    .react-3d-carousel .slider-container .slider-right div {
        padding: 0px 50px 0px 40px !important;
    }
    .react-3d-carousel .slider-container .slider-left div {
        left: 10px !important;
    }
    .react-3d-carousel .slider-container .slider-left div {
        padding: 0px 43px 0px 45px !important;
    }
}


@media only screen and (max-width: 280px) {
    .flip-btn {
        right: -20px;
    }
    .react-3d-carousel .slider-container .slider-right div {
        left: -50px !important;
    }
    .react-3d-carousel .slider-container .slider-left div {
        left: -10px !important;
    }
}





