body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: Montserrat;
  src:url(/static/media/Montserrat-Regular.34de1239.ttf);
}
@font-face {
  font-family: Montserrat-Medium;
  src:url(/static/media/Montserrat-Medium.aca6287f.ttf);
}
@font-face {
  font-family: Montserrat-Bold;
  src:url(/static/media/Montserrat-Bold.d14ad103.ttf);
}
@font-face {
  font-family: Montserrat-Semi-Bold;
  src:url(/static/media/Montserrat-SemiBold.7ffeec2b.ttf);
}

*{
  margin: 0;
  padding: 0;
  font-family: Montserrat;
  box-sizing: border-box;
}
.container{
  max-width: 1231px;
  width: 100%;
  margin: 0 auto;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.ReactModal__Overlay.ReactModal__Overlay--after-open{
  background-color: #00000012 !important;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.basket{
  width: 300px;
  height: 80px;
  background-color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@media only screen and (max-width: 1024px) {
  .container {
    max-width: 950px;
  }
}

@media only screen and (max-width: 768px) {
  .container {
    max-width: 650px;
  }
}


.game_container_mobile {
    display: none;
}

.game_container {
    max-width: 1231px;
    width: 100%;
    margin: 40px auto 0;
}

.game_title {
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 15px;
}

.scores {
    font-family: Montserrat-Bold;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: right;
    margin-bottom: 10px;
    color: #005BB9;
}

.game_content_container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    max-width: 1231px;
    width: 100%;
    min-height: 615px;
}

.game_content {
    display: flex;
    flex-direction: column;
    background-color: #F1F4F6;
    width: 100%;
    max-width: 1043px;
}

.game_content_inner {
    padding: 31px 20px 21px 43px;
}

.explanation—block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 15px;
    /*max-height: 40px;*/
    /*height: 100%;*/
}

.explanation—block1 {
    max-width: 560px;
    width: 100%;
    padding: 10px 15px;
    border: 1px solid #005BB9;
    border-radius: 5px;
    background-color: white;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #021E3A;
    cursor: pointer;
}

.explanation—block2_ {
    max-width: 373px;
    width: 100%;
}

.explanation—block2 {
    max-width: 276px;
    width: 100%;
    border: 1px dashed #005BB9;
    box-sizing: border-box;
    border-radius: 5px;
    text-align: center;
    font-family: Montserrat-Medium;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    cursor: pointer;
}

.drop_here {
    margin: 0 auto;
    color: #cdd7e1;
    padding-top: 15px;
}

.right .item_explanation {
    background: #005BB9;
    border-radius: 5px;
    /*width: 100%;*/
    color: #fff;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    border: none;
}

.wrong .item_explanation {
    background: #D32B2E;
    border-radius: 5px;
    border: 1px dashed #FF3D00;
    width: 100%;
    color: #FFFFFF;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    margin-left: 0px;

}

.explanation—block2.right {
    border: none;
    background-color: #005BB9;
}

.explanation—block2.wrong {
    border: none;
    background-color: #D32B2E;
}

.explanation—block2.filled.neutral {
    border: none;
}

.explanation—block2.filled.neutral .item_explanation {
    border: none;
    background-color: #005BB9;
    width: 100%;
    color: white;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.explanation—block2.item_explanation .filled .neutral {
    background: #005BB9;
    border-radius: 5px;
    width: 100%;
    color: #fff;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    border: none;
}

.game_content_explanation {
    background-color: #CDD7E1;
    max-width: 188px;
    width: 100%;
}

.game_content_explanation_inner {
    padding: 31px 20px;
}

.explanations_items {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.item_explanation {
    height: 40px;
    text-align: center;
    background-color: #F1F4F6;
    border: 1px dashed #005BB9;
    border-radius: 5px;
    font-family: Montserrat-Medium;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    color: #021E3A;
    cursor: pointer;
    padding: 12px 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 17px;
}

.answers_message {
    margin-top: 20px;
    font-size: 18px;
    color: red;
}

.buttons_block {
    margin-top: 24px;
    margin-bottom: 119px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.start_again {
    border: 1px solid #D32B2E;
    box-sizing: border-box;
    border-radius: 50px;
    background-color: white;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    max-width: 220px;
    width: 100%;
    height: 50px;
    color: #D32B2E;
    cursor: pointer;

}

.new_game {
    margin-left: 19px;
    border: 1px solid #D32B2E;
    box-sizing: border-box;
    border-radius: 50px;
    max-width: 220px;
    width: 100%;
    height: 50px;
    background-color: white;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    color: #D32B2E;
    cursor: pointer;
}

.score {
    margin-left: 19px;
    border: 1px solid #D32B2E;
    box-sizing: border-box;
    border-radius: 50px;
    max-width: 220px;
    width: 100%;
    height: 50px;
    background-color: #D32B2E;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    color: white;
    cursor: pointer;
}

.score_modal_container {
    width: 334px;
    height: 156px;
}

.your_scores {
    margin-top: 20px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #021E3A;
    text-align: center;
}

.scores_count {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 22px;
    color: #D32B2E;
    text-align: center;
    margin-top: 11px;
}

.close_icon_div {
    display: flex;
    justify-content: flex-end;
}

.close_icon {
    cursor: pointer;
}

.error_fill {
    color: red;
    margin-top: 10px;
}

@media only screen and (max-width: 1270px) {
    .game_container {
        max-width: 1100px
    }

    .container {
        max-width: 1100px;
    }

    /*.game_content_container{*/
    /*    min-height: 590px;*/
    /*}*/

    .explanation—block {
       min-height: 45px;
    }

    .explanation—block1 {
        line-height: 15px;
        padding: 8px 12px;
    }

}

@media only screen and (max-width: 1024px) {
    .game_container {
        max-width: 1000px
    }

    .game_content_inner {
        padding: 15px 15px 0px 23px;
    }

    .game_content_explanation_inner {
        padding: 15px 10px;
    }

    .explanation—block {
        margin-bottom: 10px;
    }

    .item_explanation {
        margin-bottom: 10px;
        font-size: 13px;
    }

    .explanation—block1 {
        padding: 3px 2px;
        font-size: 13px;
    }

    .explanation—block2 {
        margin-right: 20px;
    }

    .game_content_container {
        min-height: 530px;
    }
}

@media only screen and (max-width: 912px) {
    .game_container {
        max-width: 860px
    }

    .explanation—block2 {
        margin-right: 10px;
    }

    .explanation—block {
        min-height: 42px;
    }

    .game_content_container {
        min-height: 550px;
    }

    .item_explanation {
        margin-bottom: 12px;
    }

    .start_again {
        max-width: 170px;
    }
}

@media only screen and (max-width: 820px) {
    .game_container {
        display: none;
    }

    .game_container_mobile {
        display: block;
        max-width: 700px;
        width: 100%;
        margin: 30px auto 0;
    }

    .game_content_container {
        display: flex;
        flex-direction: column;
    }

    .explanations_items {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: nowrap;
        overflow-x: scroll;
        padding-bottom: 15px;
    }

    .explanation—block2.filled.neutral .item_explanation {
        margin-left: 0px;
    }

    .explanation—block1 {
        max-width: 360px;
    }

    .explanation—block2 {
        max-width: 260px;
    }

    .game_content_inner {
        padding: 15px 15px 20px 23px;
    }

    .game_title {
        text-align: center;
    }

    .error_message {
        margin-top: 10px;
        color: red;
        font-family: Montserrat;
        font-size: 18px;
    }

    .game_content_explanation {
        background-color: transparent;
        max-width: -webkit-max-content;
        max-width: max-content;
    }

    .item_explanation {
        background-color: #CDD7E1;
        margin: 0 30px;
        min-width: 100px;
    }

    .start_again {
        max-width: 130px;
    }

    .buttons_block {
        margin-top: 15px;
    }

    .error_fill {
        margin-top: 0px;
    }
    .buttons_block1{
        width: 300px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
    }
}

@media only screen and (max-width: 768px) {
    .explanation—block1 {
        max-width: 375px;
        font-size: 12px;
        line-height: 10px;
    }

    .explanation—block2 {
        max-width: 250px;
        font-size: 12px;
    }

    .game_container_mobile {
        display: block;
        max-width: 730px;
    }

    .item_explanation {
        font-size: 12px;
        line-height: 10px;
        min-width: 110px;
    }

    .buttons_block {
        margin-top: 0px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .new_game, .score {
        margin-left: 0px;
        font-size: 15px;
    }

    .start_again {
        font-size: 16px;
    }
    .error_fill{
        margin-top: 0px !important;
        margin-bottom: 10px;
    }
}

@media only screen and (max-width: 700px) {
    .game_container_mobile {
        display: block;
        max-width: 665px;
    }

    .explanation—block1 {
        max-width: 355px;
    }

    .explanation—block2 {
        max-width: 240px;
    }
}

@media only screen and (max-width: 650px) {
    .game_container_mobile {
        display: block;
        max-width: 615px;
    }

    .explanation—block1 {
        max-width: 315px;
    }

    .explanation—block2 {
        max-width: 220px;
    }
}

@media only screen and (max-width: 580px) {
    .game_container_mobile {
        display: block;
        max-width: 540px;
    }

    .explanation—block1 {
        max-width: 275px;
    }

    .explanation—block2 {
        max-width: 200px;
    }
}

@media only screen and (max-width: 540px) {
    .game_container_mobile {
        max-width: 510px;
    }

    .explanation—block1 {
        max-width: 255px;
    }

    .explanation—block2 {
        max-width: 180px;
    }
}


@media only screen and (max-width: 520px) {
    .explanation—block1 {
        max-width: 255px;
        font-size: 12px;
    }

    .explanation—block2 {
        max-width: 156px;
        font-size: 12px;
        margin-left: 10px;
        margin-right: 0px;
    }

    .game_container_mobile {
        display: block;
        max-width: 480px;
    }

    .scores {
        font-size: 14px;
    }

    .buttons_block {
        /*margin-top: 20px;*/
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .new_game, .score {
        margin-left: 0px;
        font-size: 14px;
    }

    .start_again {
        font-size: 14px;
    }

    .game_content_inner {
        padding: 15px 15px 10px 15px;
    }

    .item_explanation {
        border-radius: 15px;
    }

    .game_title {
        font-size: 18px;
    }

    .explanation—block2.filled.neutral .item_explanation {
        border-radius: 5px;
    }
}

@media only screen and (max-width: 480px) {
    .game_container_mobile {
        display: block;
        max-width: 440px;
    }
}

@media only screen and (max-width: 450px) {
    .game_container_mobile {
        display: block;
        max-width: 420px;
    }
}

@media only screen and (max-width: 414px) {
    .game_container_mobile {
        display: block;
        max-width: 380px;
    }

    .explanation—block1 {
        max-width: 205px;
    }

    .explanation—block2 {
        max-width: 126px;
        font-size: 12px;
        margin-left: 5px;
        margin-right: 0px;
    }
}

@media only screen and (max-width: 400px) {
    .explanation—block {
        height: -webkit-max-content;
        height: max-content;
    }

    .explanation—block1 {
        /*height: max-content;*/
    }

    .game_container_mobile {
        display: block;
        max-width: 360px;
    }

    .score_modal_container {
        width: 234px;
        height: 136px;
    }

}

@media only screen and (max-width: 375px) {
    .game_container_mobile {
        max-width: 340px;
    }

    .explanation—block1 {
        max-width: 175px;
    }

    .explanation—block2 {
        max-width: 116px;
    }
}

@media only screen and (max-width: 360px) {
    .game_container_mobile {
        max-width: 325px;
    }

    .explanation—block1 {
        max-width: 165px;
    }

    .explanation—block2 {
        max-width: 116px;
    }

    .item_explanation {
        margin: 0 20px;
    }
    .buttons_block1 {
        width: 270px;
    }

}

@media only screen and (max-width: 280px) {
    .game_container_mobile {
        max-width: 260px;
    }
}





























































































































































































































































































































































































































.game_desc_container {
    margin-top: 192px;
}
.new_game_dest_block{
    margin-bottom: 30px;
    max-width: 450px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

}

.new_game_dest, .new_game_button {
    border: none;
    box-sizing: border-box;
    border-radius: 50px;
    max-width: 180px;
    width: 100%;
    height: 50px;
    background-color:#D32B2E;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    color: white;
    cursor: pointer;
}

.game_desc_buttons_block {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: 548px;
    width: 100%;
    margin: 55px auto;
}

.previouse_button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 168px;
    height: 50px;
    border: 1px solid #D32B2E;
    border-radius: 50px;
    background-color: #FFFFFF;
    color: #D32B2E;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    cursor: pointer;
}
.previouse_button::before{
    content: "\AB";
    font-size: 30px;
    margin-bottom: 5px;
    margin-right: 7px;
}

.scores_count_ {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #021E3A;
    border: none;
}

.next_button {
    width: 168px;
    height: 50px;
    left: 1046px;
    top: 702px;
    background: #D32B2E;
    border-radius: 50px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.next_button::after {
    content: "\BB";
    font-size: 30px;
    margin-bottom: 5px;
    margin-left: 7px;
}
.arrow_right {
    margin-left: 11px;
}

.arrow_left {
    margin-right: 11px;
}

@media only screen and (max-width: 600px) {
    .game_desc_buttons_block {
       width: 300px;
    }
    .scores_count_ {
      margin-top: -100px;
    }
}
* {
    background-color: transparent;
}


.flip-card {
    background-color: transparent;
    width: 508px;
    height: 366px;
    border: 1px solid #CDD7E1;
    -webkit-perspective: 1000px;
            perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: -webkit-transform 0.8s;
    transition: transform 0.8s;
    transition: transform 0.8s, -webkit-transform 0.8s;
    transform-style: preserve-3d;
    -webkit-transform-style:preserve-3d;
}

.flip-btn {
    position: absolute;
    right: 169px;
    bottom: 32px;
    z-index: 1;
    background: transparent;
    color: #D32B2E;
    border: none;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.flip-btn2 {
    position: absolute;
    right: 30px;
    bottom: 32px;
    z-index: 1;
    background: transparent;
    color: #D32B2E;
    border: none;
    display: flex;
    align-items: center;
    cursor: pointer;
}


.refresh_icon {
    margin-right: 7px;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-btn {
    opacity: 1;
    transition: 300ms ease opacity;

}
/*.flip-btn:focus{*/
/*    opacity: 0;*/
/*}*/
.flip-btn2:focus{
    opacity: 0;
}


.flip-btn.focus + .flip-card .flip-card-inner {
    transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
    background-color: #bbb;
    color: black;
}

/* Style the back side */
.flip-card-back {
    background-color: #CDD7E1;
    text-align: center;
    margin: auto;
    color: #021E3A;
    transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.card {
    width: 508px;
    height: 366px;
    -webkit-perspective: 1000px;
            perspective: 1000px;
    background: #F1F4F6;
    border: 1px solid #CDD7E1;
    box-shadow: 0px 4px 25px rgba(100, 100, 100, 0.25);
    border-radius: 6px;
    position: relative;
}

.card_inner {
    margin: 14px;
    height: 339px;
    border: 1px dashed #005BB9;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.card_inner_back {
    margin: 14px;
    height: 339px;
    border: 1px dashed #005BB9;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 480px;
}

.sound {
    position: absolute;
    bottom: 30px;
    left: 30px;
    cursor: pointer;

}

.react-3d-carousel {
    background-color: white;
}

.word {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #021E3A;
}

.fa-arrow-right:before {
    content: "\F061";
    color: #021E3A;
}

.fa-arrow-left:before {
    content: "\F060";
    color: #021E3A;
}

.react-3d-carousel .slider-container .slider-content .slider-single.active .slider-single-content {
    opacity: 1;
    -webkit-transform: translateX(0%) scale(1);
            transform: translateX(0%) scale(1);
    background: #F1F4F6;
}

.react-3d-carousel .slider-container .slider-content .slider-single.proactive .slider-single-content {
    opacity: 0.3;
    -webkit-transform: translateX(25%) scale(0.8);
            transform: translateX(25%) scale(0.8);
    background: #F1F4F6;
}

.react-3d-carousel .slider-container .slider-content .slider-single .slider-single-content {
    background: #F1F4F6;
}

.audio_button {
    border: none;
    cursor: pointer;
}

.react-3d-carousel .slider-container .slider-right div {
    position: absolute !important;
    top: 126% !important;
    left: -290px !important;
    margin-top: -20px !important;
    color: #ffffff !important;
    -webkit-transform: translateY(-50%) !important;
            transform: translateY(-50%) !important;
    padding: 0px 60px 0px 70px !important;
    border-top: none !important;
    border-right: none !important;
    border-bottom: none !important;
    border-left: none !important;
}

.react-3d-carousel .slider-container .slider-left div {
    position: absolute !important;
    top: 127% !important;
    left: 155px !important;
    margin-top: -20px !important;
    color: #ffffff !important;
    -webkit-transform: translateY(-50%) !important;
            transform: translateY(-50%) !important;
    padding: 0px 60px 0px 70px !important;
    border-top: none !important;
    border-right: none !important;
    border-bottom: none !important;
    border-left: none !important;
}

.fa {
    display: inline-block;
    font: normal normal normal 48px/1 FontAwesome !important;
    /*font-size: unset;*/
    /*text-rendering: */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.fa-arrow-right:before {
    color: transparent;
}

.fa-arrow-left:before {
    color: transparent;
}

@media only screen and (max-width: 1270px) {
    .react-3d-carousel .slider-container .slider-right div {
        left: -245px !important;
    }

    .react-3d-carousel .slider-container .slider-left div {
        left: 110px !important;
    }

    .flip-btn {
        right: 129px;
    }
}

@media only screen and (max-width: 1060px) {
    .react-3d-carousel .slider-container .slider-right div {
        left: -235px !important;
    }

    .react-3d-carousel .slider-container .slider-left div {
        left: 100px !important;
    }
}

@media only screen and (max-width: 1024px) {
    .react-3d-carousel .slider-container .slider-right div {
        left: -225px !important;
    }

    .react-3d-carousel .slider-container .slider-left div {
        left: 90px !important;
    }
    .flip-btn {
        right: 109px;
    }
}

@media only screen and (max-width: 960px) {
    .react-3d-carousel .slider-container .slider-right div {
        left: -205px !important;
    }

    .react-3d-carousel .slider-container .slider-left div {
        left: 70px !important;
    }

    .flip-btn {
        right: 79px;
    }
}
@media only screen and (max-width: 920px) {
    .flip-card {
        width: 420px;
    }

    .card {
        width: 420px;
    }
    .card_inner_back {
        width: 390px;
    }
    .flip-btn {
        right: 119px;
    }
}

@media only screen and (max-width: 912px) {
    .react-3d-carousel .slider-container .slider-right div {
        left: -185px !important;
    }

    .react-3d-carousel .slider-container .slider-left div {
        left: 50px !important;
    }

    .react-3d-carousel .slider-container .slider-content .slider-single.active .slider-single-content {
        opacity: 1;
        -webkit-transform: translateX(0%) scale(1);
                transform: translateX(0%) scale(1);
        background: #F1F4F6;
    }

}


@media only screen and (max-width: 860px) {
    .react-3d-carousel .slider-container .slider-right div {
        left: -175px !important;
    }

    .react-3d-carousel .slider-container .slider-left div {
        left: 30px !important;
    }

}

@media only screen and (max-width: 820px) {
    .react-3d-carousel .slider-container .slider-right div {
        left: -130px !important;
    }
    .react-3d-carousel .slider-container .slider-right div {
        padding: 0px 50px 0px 55px !important;
    }
    .react-3d-carousel .slider-container .slider-left div {
        left: 20px !important;
    }
    .react-3d-carousel .slider-container .slider-left div {
        padding: 0px 43px 0px 45px !important;
    }

    .flip-btn {
        right: 79px;
    }
}

@media only screen and (max-width: 768px) {
    .react-3d-carousel .slider-container .slider-right div {
        left: -110px !important;
    }

    .react-3d-carousel .slider-container .slider-left div {
        left: 10px !important;
    }
    .flip-card {
        width: 320px;
    }

    .card {
        width: 320px;
    }
    .card_inner_back {
        width: 290px;
    }
    .flip-btn {
        right: 109px;
    }
}

@media only screen and (max-width: 650px) {
    .flip-btn {
        right: 79px;
    }
    .react-3d-carousel .slider-container .slider-right div {
        left: -60px !important;
    }
    .react-3d-carousel .slider-container .slider-right div {
        padding: 0px 50px 0px 30px !important;
    }
    .react-3d-carousel .slider-container .slider-left div {
        left: -25px !important;
    }
    .react-3d-carousel .slider-container .slider-left div {
        padding: 0px 43px 0px 45px !important;
    }
}

@media only screen and (max-width: 580px) {
    .flip-btn {
        right: 59px;
    }
    .flip-card {
        width: 300px;
    }

    .card {
        width: 300px;
    }
    .card_inner_back {
        width: 270px;
    }
    .react-3d-carousel .slider-container .slider-right div {
        left: -160px !important;
    }
    .react-3d-carousel .slider-container .slider-right div {
        padding: 0px 50px 0px 30px !important;
    }
    .react-3d-carousel .slider-container .slider-left div {
        left: 70px !important;
    }
    .react-3d-carousel .slider-container .slider-left div {
        padding: 0px 43px 0px 45px !important;
    }
}

@media only screen and (max-width: 440px) {
    .flip-btn {
        right: 29px;
    }
    .react-3d-carousel .slider-container .slider-right div {
        left: -120px !important;
    }
    .react-3d-carousel .slider-container .slider-right div {
        padding: 0px 50px 0px 40px !important;
    }
    .react-3d-carousel .slider-container .slider-left div {
        left: 30px !important;
    }
    .react-3d-carousel .slider-container .slider-left div {
        padding: 0px 43px 0px 45px !important;
    }
}
@media only screen and (max-width: 414px) {
    .flip-btn {
        right: 19px;
    }
    .react-3d-carousel .slider-container .slider-right div {
        left: -110px !important;
    }
    .react-3d-carousel .slider-container .slider-right div {
        padding: 0px 50px 0px 40px !important;
    }
    .react-3d-carousel .slider-container .slider-left div {
        left: 15px !important;
    }
    .react-3d-carousel .slider-container .slider-left div {
        padding: 0px 43px 0px 45px !important;
    }

}
@media only screen and (max-width: 390px){
    .flip-btn {
        right: 7px;
    }
}
@media only screen and (max-width: 375px) {
    .flip-btn {
        right: 0px;
    }
    .react-3d-carousel .slider-container .slider-right div {
        left: -100px !important;
    }
    .react-3d-carousel .slider-container .slider-right div {
        padding: 0px 50px 0px 40px !important;
    }
    .react-3d-carousel .slider-container .slider-left div {
        left: 10px !important;
    }
    .react-3d-carousel .slider-container .slider-left div {
        padding: 0px 43px 0px 45px !important;
    }
}


@media only screen and (max-width: 280px) {
    .flip-btn {
        right: -20px;
    }
    .react-3d-carousel .slider-container .slider-right div {
        left: -50px !important;
    }
    .react-3d-carousel .slider-container .slider-left div {
        left: -10px !important;
    }
}






.textarea {
    width: 300px;
    height: 200px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #021E3A;
    text-align: center;
    /*border: 1px dotted #005BB9;*/
    border: none;
}
.textarea:focus{
    outline: none;
}
.new_game_score_button{
    max-width: 750px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin:0px auto 50px;
}

@media only screen and  (max-width: 820px) {
    .new_game_button {
        border: none;
        box-sizing: border-box;
        border-radius: 50px;
        max-width: 180px;
        width: 100%;
        height: 50px;
        background-color:#D32B2E;
        font-family: Montserrat;
        font-size: 18px;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: center;
        color: white;
        cursor: pointer;
    }
    .new_game_score_button{
        max-width: 500px;
    }
}
@media only screen and  (max-width: 620px) {
    .new_game_button{
    max-width: 140px;
}
}

@media only screen and  (max-width: 550px) {
    .new_game_score_button{
        max-width: 350px;
    }
}
@media only screen and  (max-width: 414px) {
    .new_game_score_button{
        max-width: 300px;
    }
}


