.textarea {
    width: 300px;
    height: 200px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #021E3A;
    text-align: center;
    /*border: 1px dotted #005BB9;*/
    border: none;
}
.textarea:focus{
    outline: none;
}
.new_game_score_button{
    max-width: 750px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin:0px auto 50px;
}

@media only screen and  (max-width: 820px) {
    .new_game_button {
        border: none;
        box-sizing: border-box;
        border-radius: 50px;
        max-width: 180px;
        width: 100%;
        height: 50px;
        background-color:#D32B2E;
        font-family: Montserrat;
        font-size: 18px;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: center;
        color: white;
        cursor: pointer;
    }
    .new_game_score_button{
        max-width: 500px;
    }
}
@media only screen and  (max-width: 620px) {
    .new_game_button{
    max-width: 140px;
}
}

@media only screen and  (max-width: 550px) {
    .new_game_score_button{
        max-width: 350px;
    }
}
@media only screen and  (max-width: 414px) {
    .new_game_score_button{
        max-width: 300px;
    }
}

