.game_desc_container {
    margin-top: 192px;
}
.new_game_dest_block{
    margin-bottom: 30px;
    max-width: 450px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

}

.new_game_dest, .new_game_button {
    border: none;
    box-sizing: border-box;
    border-radius: 50px;
    max-width: 180px;
    width: 100%;
    height: 50px;
    background-color:#D32B2E;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    color: white;
    cursor: pointer;
}

.game_desc_buttons_block {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: 548px;
    width: 100%;
    margin: 55px auto;
}

.previouse_button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 168px;
    height: 50px;
    border: 1px solid #D32B2E;
    border-radius: 50px;
    background-color: #FFFFFF;
    color: #D32B2E;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    cursor: pointer;
}
.previouse_button::before{
    content: "«";
    font-size: 30px;
    margin-bottom: 5px;
    margin-right: 7px;
}

.scores_count_ {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #021E3A;
    border: none;
}

.next_button {
    width: 168px;
    height: 50px;
    left: 1046px;
    top: 702px;
    background: #D32B2E;
    border-radius: 50px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.next_button::after {
    content: "»";
    font-size: 30px;
    margin-bottom: 5px;
    margin-left: 7px;
}
.arrow_right {
    margin-left: 11px;
}

.arrow_left {
    margin-right: 11px;
}

@media only screen and (max-width: 600px) {
    .game_desc_buttons_block {
       width: 300px;
    }
    .scores_count_ {
      margin-top: -100px;
    }
}