.game_container_mobile {
    display: none;
}

.game_container {
    max-width: 1231px;
    width: 100%;
    margin: 40px auto 0;
}

.game_title {
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 15px;
}

.scores {
    font-family: Montserrat-Bold;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: right;
    margin-bottom: 10px;
    color: #005BB9;
}

.game_content_container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    max-width: 1231px;
    width: 100%;
    min-height: 615px;
}

.game_content {
    display: flex;
    flex-direction: column;
    background-color: #F1F4F6;
    width: 100%;
    max-width: 1043px;
}

.game_content_inner {
    padding: 31px 20px 21px 43px;
}

.explanation—block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 15px;
    /*max-height: 40px;*/
    /*height: 100%;*/
}

.explanation—block1 {
    max-width: 560px;
    width: 100%;
    padding: 10px 15px;
    border: 1px solid #005BB9;
    border-radius: 5px;
    background-color: white;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #021E3A;
    cursor: pointer;
}

.explanation—block2_ {
    max-width: 373px;
    width: 100%;
}

.explanation—block2 {
    max-width: 276px;
    width: 100%;
    border: 1px dashed #005BB9;
    box-sizing: border-box;
    border-radius: 5px;
    text-align: center;
    font-family: Montserrat-Medium;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    cursor: pointer;
}

.drop_here {
    margin: 0 auto;
    color: #cdd7e1;
    padding-top: 15px;
}

.right .item_explanation {
    background: #005BB9;
    border-radius: 5px;
    /*width: 100%;*/
    color: #fff;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    border: none;
}

.wrong .item_explanation {
    background: #D32B2E;
    border-radius: 5px;
    border: 1px dashed #FF3D00;
    width: 100%;
    color: #FFFFFF;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    margin-left: 0px;

}

.explanation—block2.right {
    border: none;
    background-color: #005BB9;
}

.explanation—block2.wrong {
    border: none;
    background-color: #D32B2E;
}

.explanation—block2.filled.neutral {
    border: none;
}

.explanation—block2.filled.neutral .item_explanation {
    border: none;
    background-color: #005BB9;
    width: 100%;
    color: white;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.explanation—block2.item_explanation .filled .neutral {
    background: #005BB9;
    border-radius: 5px;
    width: 100%;
    color: #fff;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    border: none;
}

.game_content_explanation {
    background-color: #CDD7E1;
    max-width: 188px;
    width: 100%;
}

.game_content_explanation_inner {
    padding: 31px 20px;
}

.explanations_items {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.item_explanation {
    height: 40px;
    text-align: center;
    background-color: #F1F4F6;
    border: 1px dashed #005BB9;
    border-radius: 5px;
    font-family: Montserrat-Medium;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    color: #021E3A;
    cursor: pointer;
    padding: 12px 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 17px;
}

.answers_message {
    margin-top: 20px;
    font-size: 18px;
    color: red;
}

.buttons_block {
    margin-top: 24px;
    margin-bottom: 119px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.start_again {
    border: 1px solid #D32B2E;
    box-sizing: border-box;
    border-radius: 50px;
    background-color: white;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    max-width: 220px;
    width: 100%;
    height: 50px;
    color: #D32B2E;
    cursor: pointer;

}

.new_game {
    margin-left: 19px;
    border: 1px solid #D32B2E;
    box-sizing: border-box;
    border-radius: 50px;
    max-width: 220px;
    width: 100%;
    height: 50px;
    background-color: white;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    color: #D32B2E;
    cursor: pointer;
}

.score {
    margin-left: 19px;
    border: 1px solid #D32B2E;
    box-sizing: border-box;
    border-radius: 50px;
    max-width: 220px;
    width: 100%;
    height: 50px;
    background-color: #D32B2E;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    color: white;
    cursor: pointer;
}

.score_modal_container {
    width: 334px;
    height: 156px;
}

.your_scores {
    margin-top: 20px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #021E3A;
    text-align: center;
}

.scores_count {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 22px;
    color: #D32B2E;
    text-align: center;
    margin-top: 11px;
}

.close_icon_div {
    display: flex;
    justify-content: flex-end;
}

.close_icon {
    cursor: pointer;
}

.error_fill {
    color: red;
    margin-top: 10px;
}

@media only screen and (max-width: 1270px) {
    .game_container {
        max-width: 1100px
    }

    .container {
        max-width: 1100px;
    }

    /*.game_content_container{*/
    /*    min-height: 590px;*/
    /*}*/

    .explanation—block {
       min-height: 45px;
    }

    .explanation—block1 {
        line-height: 15px;
        padding: 8px 12px;
    }

}

@media only screen and (max-width: 1024px) {
    .game_container {
        max-width: 1000px
    }

    .game_content_inner {
        padding: 15px 15px 0px 23px;
    }

    .game_content_explanation_inner {
        padding: 15px 10px;
    }

    .explanation—block {
        margin-bottom: 10px;
    }

    .item_explanation {
        margin-bottom: 10px;
        font-size: 13px;
    }

    .explanation—block1 {
        padding: 3px 2px;
        font-size: 13px;
    }

    .explanation—block2 {
        margin-right: 20px;
    }

    .game_content_container {
        min-height: 530px;
    }
}

@media only screen and (max-width: 912px) {
    .game_container {
        max-width: 860px
    }

    .explanation—block2 {
        margin-right: 10px;
    }

    .explanation—block {
        min-height: 42px;
    }

    .game_content_container {
        min-height: 550px;
    }

    .item_explanation {
        margin-bottom: 12px;
    }

    .start_again {
        max-width: 170px;
    }
}

@media only screen and (max-width: 820px) {
    .game_container {
        display: none;
    }

    .game_container_mobile {
        display: block;
        max-width: 700px;
        width: 100%;
        margin: 30px auto 0;
    }

    .game_content_container {
        display: flex;
        flex-direction: column;
    }

    .explanations_items {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: nowrap;
        overflow-x: scroll;
        padding-bottom: 15px;
    }

    .explanation—block2.filled.neutral .item_explanation {
        margin-left: 0px;
    }

    .explanation—block1 {
        max-width: 360px;
    }

    .explanation—block2 {
        max-width: 260px;
    }

    .game_content_inner {
        padding: 15px 15px 20px 23px;
    }

    .game_title {
        text-align: center;
    }

    .error_message {
        margin-top: 10px;
        color: red;
        font-family: Montserrat;
        font-size: 18px;
    }

    .game_content_explanation {
        background-color: transparent;
        max-width: max-content;
    }

    .item_explanation {
        background-color: #CDD7E1;
        margin: 0 30px;
        min-width: 100px;
    }

    .start_again {
        max-width: 130px;
    }

    .buttons_block {
        margin-top: 15px;
    }

    .error_fill {
        margin-top: 0px;
    }
    .buttons_block1{
        width: 300px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
    }
}

@media only screen and (max-width: 768px) {
    .explanation—block1 {
        max-width: 375px;
        font-size: 12px;
        line-height: 10px;
    }

    .explanation—block2 {
        max-width: 250px;
        font-size: 12px;
    }

    .game_container_mobile {
        display: block;
        max-width: 730px;
    }

    .item_explanation {
        font-size: 12px;
        line-height: 10px;
        min-width: 110px;
    }

    .buttons_block {
        margin-top: 0px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .new_game, .score {
        margin-left: 0px;
        font-size: 15px;
    }

    .start_again {
        font-size: 16px;
    }
    .error_fill{
        margin-top: 0px !important;
        margin-bottom: 10px;
    }
}

@media only screen and (max-width: 700px) {
    .game_container_mobile {
        display: block;
        max-width: 665px;
    }

    .explanation—block1 {
        max-width: 355px;
    }

    .explanation—block2 {
        max-width: 240px;
    }
}

@media only screen and (max-width: 650px) {
    .game_container_mobile {
        display: block;
        max-width: 615px;
    }

    .explanation—block1 {
        max-width: 315px;
    }

    .explanation—block2 {
        max-width: 220px;
    }
}

@media only screen and (max-width: 580px) {
    .game_container_mobile {
        display: block;
        max-width: 540px;
    }

    .explanation—block1 {
        max-width: 275px;
    }

    .explanation—block2 {
        max-width: 200px;
    }
}

@media only screen and (max-width: 540px) {
    .game_container_mobile {
        max-width: 510px;
    }

    .explanation—block1 {
        max-width: 255px;
    }

    .explanation—block2 {
        max-width: 180px;
    }
}


@media only screen and (max-width: 520px) {
    .explanation—block1 {
        max-width: 255px;
        font-size: 12px;
    }

    .explanation—block2 {
        max-width: 156px;
        font-size: 12px;
        margin-left: 10px;
        margin-right: 0px;
    }

    .game_container_mobile {
        display: block;
        max-width: 480px;
    }

    .scores {
        font-size: 14px;
    }

    .buttons_block {
        /*margin-top: 20px;*/
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .new_game, .score {
        margin-left: 0px;
        font-size: 14px;
    }

    .start_again {
        font-size: 14px;
    }

    .game_content_inner {
        padding: 15px 15px 10px 15px;
    }

    .item_explanation {
        border-radius: 15px;
    }

    .game_title {
        font-size: 18px;
    }

    .explanation—block2.filled.neutral .item_explanation {
        border-radius: 5px;
    }
}

@media only screen and (max-width: 480px) {
    .game_container_mobile {
        display: block;
        max-width: 440px;
    }
}

@media only screen and (max-width: 450px) {
    .game_container_mobile {
        display: block;
        max-width: 420px;
    }
}

@media only screen and (max-width: 414px) {
    .game_container_mobile {
        display: block;
        max-width: 380px;
    }

    .explanation—block1 {
        max-width: 205px;
    }

    .explanation—block2 {
        max-width: 126px;
        font-size: 12px;
        margin-left: 5px;
        margin-right: 0px;
    }
}

@media only screen and (max-width: 400px) {
    .explanation—block {
        height: max-content;
    }

    .explanation—block1 {
        /*height: max-content;*/
    }

    .game_container_mobile {
        display: block;
        max-width: 360px;
    }

    .score_modal_container {
        width: 234px;
        height: 136px;
    }

}

@media only screen and (max-width: 375px) {
    .game_container_mobile {
        max-width: 340px;
    }

    .explanation—block1 {
        max-width: 175px;
    }

    .explanation—block2 {
        max-width: 116px;
    }
}

@media only screen and (max-width: 360px) {
    .game_container_mobile {
        max-width: 325px;
    }

    .explanation—block1 {
        max-width: 165px;
    }

    .explanation—block2 {
        max-width: 116px;
    }

    .item_explanation {
        margin: 0 20px;
    }
    .buttons_block1 {
        width: 270px;
    }

}

@media only screen and (max-width: 280px) {
    .game_container_mobile {
        max-width: 260px;
    }
}



























































































































































































































































































































































































































